import React from 'react';

import NotFoundPage from '../../components/NotFoundPage';
import SEOContainer from '../Layout/SEO';
import SectionFooterContactDefaultContainer from '../Section/SectionFooterContactDefault';
import theme from '../../utils/styling/theme';

// Types

type Props = {};

const NotFoundPageContainer = (_props: Props) => {
  const footer = <SectionFooterContactDefaultContainer backgroundColor={theme.color.grey} textColor={theme.color.white} />;

  return (
    <>
      <SEOContainer title="404 – Not Found" openGraphTitle="404 – Not Found" />

      <NotFoundPage footer={footer} />
    </>
  );
};

export default NotFoundPageContainer;
