import styled from '../../utils/styling/styled';

import Heading from '../Heading';
import styleUtils from '../../utils/styling/styleUtils';

export const Subtitle = styled.span`
  color: ${props => props.theme.color.white};
  font-size: 1.5rem;
  text-transform: uppercase;
`;

export const Title = styled(Heading)`
  ${styleUtils.margin.negativeVertical.bottom};

  ${styleUtils.font.shadow.default};
  ${styleUtils.font.weight.bold};

  color: ${props => props.theme.color.brand};

  font-size: 24rem;

  ${styleUtils.media.orientation.portrait} {
    font-size: 20rem;
  }
`;
