import React from 'react';

import SectionStaticPageContent from '../Section/SectionStaticPageContent';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  footer: ReactNode;
};

const NotFoundPage = ({ footer }: Props) => {
  return (
    <>
      <SectionStaticPageContent contentPositionX="center" contentPositionY="center" sectionWidth={75}>
        <S.Title>404</S.Title>
        <S.Subtitle>The requested file cannot be found.</S.Subtitle>
      </SectionStaticPageContent>

      {footer}
    </>
  );
};

export default NotFoundPage;
