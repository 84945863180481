import React from 'react';

import LayoutContainer from '../containers/Layout';
import NotFoundPageContainer from '../containers/NotFoundPage';

const NotFoundPagePage = () => {
  return (
    <LayoutContainer backgroundColor="#313131">
      <NotFoundPageContainer />
    </LayoutContainer>
  );
};

export default NotFoundPagePage;
